import ReactPDF, {View} from "@react-pdf/renderer";
import {pxToPt} from "helpers/transform";
import {SectionTitle} from "../SectionTitle";

export const InfoBlockContainer = ({
  children,
  title,
  wrap = true,
  style
}: {
  children: React.ReactNode;
  title: string;
  wrap?: boolean;
  style?: ReactPDF.ViewProps["style"];
}) => {
  return (
    <View style={{marginBottom: pxToPt(10), ...style}} wrap={wrap}>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </View>
  );
};
