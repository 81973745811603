import {View, StyleSheet} from "@react-pdf/renderer";
import {PDFText} from "components/PDFText";
import {tEstimateGroup} from "api/budget/estimate/types";
import {pxToPt} from "helpers/transform";
import {tEstimateValuesCollStyles} from "./EstimateValues";

type tEstimateValuesHeadProps = {data: tEstimateGroup; collStyles: tEstimateValuesCollStyles};

export const EstimateValuesHead = ({data, collStyles}: tEstimateValuesHeadProps) => {
  const {row, bigText, regularText} = styles;
  return (
    <View style={row} wrap={false}>
      <View style={{...collStyles?.colCode, alignSelf: "flex-start"}}>
        <PDFText fontFamily="Helvetica700" style={bigText}>
          {data?.code}
        </PDFText>
      </View>
      <View style={collStyles.colName}>
        <PDFText fontFamily="Helvetica700" style={bigText}>
          {data?.name}
        </PDFText>
      </View>
      <View style={collStyles.colQty}>
        <PDFText fontFamily="Helvetica500" style={{...regularText, textAlign: "center"}}>
          qty
        </PDFText>
      </View>
      <View style={collStyles.colCost}>
        <PDFText fontFamily="Helvetica500" style={regularText}>
          cost
        </PDFText>
      </View>
      <View style={collStyles.colTime}>
        <PDFText fontFamily="Helvetica500" style={regularText}>
          time
        </PDFText>
      </View>
      <View style={collStyles.colAgency}>
        <PDFText fontFamily="Helvetica500" style={{...regularText, textAlign: "center"}}>
          agency %
        </PDFText>
      </View>
      <View style={collStyles.colTotal}>
        <PDFText fontFamily="Helvetica500" style={{...regularText, textAlign: "right"}}>
          total
        </PDFText>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    borderBottom: `2px solid #000`,
    paddingBottom: pxToPt(6),
    marginBottom: pxToPt(16)
  },
  bigText: {
    fontSize: pxToPt(17),
    textTransform: "uppercase"
  },
  regularText: {
    fontSize: pxToPt(10),
    textTransform: "uppercase"
  }
});
