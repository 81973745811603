import {useRef, FC, useEffect, useState} from "react";
import {SchedulerFilters} from "./common/SchedulerFilters";
import {useSetApiParamsReturn} from "hooks/useSetApiParams";
import {SchedulerControls} from "./common/SchedularControls";
import {tDepartment} from "api/common/types";
import {renderToString} from "react-dom/server";
import {SchedulerEventBar} from "./common/SchedularEventBar";
// import "dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";

import "./SchedulerDashboard.scss";

export type tSchedulerEvent = {
  start_date: string;
  end_date: string;
  name: string;
  departments: Array<tDepartment>;
};

interface iProps {
  events: any;
  apiParams: useSetApiParamsReturn;
}

export const SchedulerDashboard: FC<iProps> = ({events, apiParams}) => {
  // @ts-ignore
  const schedulerDashboard = window.scheduler;

  const schedulerRef = useRef(null);

  const [headerTitle, setHeaderTitle] = useState("");

  const initSchedulerEvents = () => {
    if (schedulerDashboard._$initialized) {
      return;
    }

    schedulerDashboard.attachEvent("onScaleDblClick", () => {});
    schedulerDashboard.attachEvent("onBeforeDrag", () => false);
    schedulerDashboard.attachEvent("onBeforeLightbox", () => false);
    schedulerDashboard._$initialized = true;
  };

  useEffect(() => {
    const formatMonthScale = schedulerDashboard.date.date_to_str("%D");
    schedulerDashboard.skin = "material";
    schedulerDashboard.xy.nav_height = 0;
    schedulerDashboard.config.header = [];

    initSchedulerEvents();

    schedulerDashboard.templates.month_scale_date = (date: Date) => {
      return formatMonthScale(date);
    };

    schedulerDashboard.templates.month_date = (date: Date) => {
      const dateToStr_func = schedulerDashboard.date.date_to_str(
        schedulerDashboard.config.month_date
      );
      setHeaderTitle(dateToStr_func(date));
      return dateToStr_func(date);
    };

    schedulerDashboard.templates.month_date_class = (date: Date) => {
      const day = date.getDay();
      const isWeekendDay: boolean = day === 6 || day === 0;
      return isWeekendDay ? "is-weekend-day" : "";
    };

    schedulerDashboard.plugins({
      tooltip: true
    });

    schedulerDashboard.config.dblclick_create = false;
    schedulerDashboard.config.start_on_monday = false;
    //schedulerDashboard.config.container_autoresize = false;
    schedulerDashboard.config.resize_month_events = true;
    schedulerDashboard.config.update_render = true;
    schedulerDashboard.xy.scroll_width = 0;
    schedulerDashboard.init(schedulerRef.current, new Date(), "month", "%D");
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!events || events?.some((item: any) => !item?.departments)) return;

    schedulerDashboard.templates.tooltip_text = (
      start: Date,
      end: Date,
      event: tSchedulerEvent
    ) => {
      return `<div class="SchedulerDashboard-tooltip"> ${event.name}</div>`;
    };

    schedulerDashboard.templates.event_bar_text = (
      start: Date,
      end: Date,
      event: tSchedulerEvent
    ) => {
      if (!event?.departments) return "";

      return renderToString(<SchedulerEventBar event={event} />);
    };

    schedulerDashboard.templates.event_class = (start: Date, end: Date, event: tSchedulerEvent) => {
      if (!event?.departments) return;
      return event?.departments?.[0]?.name || "";
    };

    schedulerDashboard.clearAll();
    schedulerDashboard.updateView();
    schedulerDashboard.parse(events);
  }, [events]); // eslint-disable-line

  return (
    <div id="SchedulerDashboard" className="SchedulerDashboard">
      <div className="SchedulerDashboard-toolbar">
        <h2 className="h2 SchedulerDashboard-title">{headerTitle}</h2>
        <SchedulerControls schedulerDashboard={schedulerDashboard} />
        {/* Filter should be visible for Admin and User+ */}
        <SchedulerFilters apiParams={apiParams} />
      </div>

      <div
        id="SchedulerDashboardCalendar"
        className="SchedulerDashboard-calendar"
        ref={schedulerRef}
      />
    </div>
  );
};
