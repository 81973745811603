import {FC} from "react";
import {StyleSheet, View, Image} from "@react-pdf/renderer";
import logoPNG from "./logo.png";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";

type tHeaderProps = {
  callSheetName: string;
  date: string;
  jobName: string;
  clientName: any;
  artist: any;
};

export const Header: FC<tHeaderProps> = ({artist, callSheetName, clientName, date, jobName}) => {
  const subTitle = [clientName, jobName, artist].join(" | ");
  return (
    <View style={styles.header}>
      <View style={styles.columnLeft}>
        <Image style={styles.logoTitle} src={logoPNG} />
        <PDFText fontFamily="Helvetica700" style={styles.subTitle}>
          {subTitle}
        </PDFText>
      </View>
      <View style={styles.columnRight}>
        <PDFText fontFamily="Helvetica700" style={styles.rightColumnText}>
          {callSheetName}
        </PDFText>
        <PDFText fontFamily="Helvetica700" style={styles.rightColumnText}>
          {date}
        </PDFText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: pxToPt(48)
  },
  columnLeft: {
    width: "70%",
    paddingRight: 10
  },
  columnRight: {
    width: "30%",
    flexShrink: 0,
    alignSelf: "flex-start",
    backgroundColor: "black",
    padding: `${pxToPt(8)} ${pxToPt(16)}`
  },
  rightColumnText: {
    color: "white",
    fontSize: pxToPt(20),
    textTransform: "uppercase"
  },
  logoTitle: {
    maxWidth: "98%"
  },
  subTitle: {
    paddingTop: pxToPt(20),
    fontSize: pxToPt(26)
  }
});
