import {FC} from "react";
import {View, StyleSheet, Image} from "@react-pdf/renderer";
import {tCallSheetLocation} from "api/callSheet/types";
import {SectionTitle} from "./SectionTitle";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";

type tLocationsProps = {
  image: string | undefined;
  locations: Array<tCallSheetLocation>;
};

export const Locations: FC<tLocationsProps> = ({image, locations}) => {
  return (
    <View style={styles.body}>
      <View style={styles.columnLeft}>
        <SectionTitle>Locations</SectionTitle>

        {locations.map((item, i) => {
          const st = i === locations?.length - 1 ? 0 : pxToPt(32);
          return (
            <View key={item?.id} style={{paddingBottom: st}} wrap={false}>
              <PDFText fontFamily="Helvetica700" style={styles.itemTitle}>
                {item?.label}
              </PDFText>
              <PDFText style={styles.itemText}>{item?.name}</PDFText>
              <PDFText style={styles.itemText}>{item?.address?.street}</PDFText>
              <PDFText style={styles.itemText}>{item?.address?.city}</PDFText>
              <PDFText style={styles.itemText}>{item?.address?.raw}</PDFText>
            </View>
          );
        })}
      </View>

      <View style={styles.columnRight}>
        {image && (
          <Image
            src={{uri: image, method: "GET", headers: {"Cache-Control": "no-cache"}, body: ""}}
            style={{maxWidth: "100%"}}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: pxToPt(56)
  },
  columnLeft: {
    width: "37%",
    alignSelf: "flex-start",
    paddingRight: 10
  },
  columnRight: {
    width: "63%",
    flexShrink: 0,
    alignSelf: "flex-start"
  },
  itemTitle: {
    paddingBottom: pxToPt(4),
    fontSize: pxToPt(15),
    textTransform: "uppercase"
  },
  itemText: {
    color: "#7e7e80",
    fontSize: pxToPt(15)
  }
});
