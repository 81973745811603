import {FC} from "react";
import {View, StyleSheet} from "@react-pdf/renderer";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {getUsMoneyFormat} from "helpers/formattingData";

export const HeaderRow: FC<{category: any}> = ({category}) => (
  <View style={styles.headerRow}>
    <PDFText fontFamily="Helvetica700" style={{...styles.headerCode, ...styles?.col1}}>
      {category?.code}
    </PDFText>
    <PDFText fontFamily="Helvetica700" style={{...styles.headerName, ...styles?.col2}}>
      {category?.name}
    </PDFText>
    <PDFText fontFamily="Helvetica500" style={styles.headerTotal}>
      total
    </PDFText>
  </View>
);

export const CostRow: FC<{costValue: any; totalActual: number}> = ({costValue, totalActual}) => (
  <View style={styles.row}>
    <PDFText style={{...styles.col1, ...styles.code}}>{costValue.code + costValue.suffix}</PDFText>
    <View style={styles.col2}>
      <PDFText style={styles.name}>{costValue.name}</PDFText>
      {costValue.description && (
        <PDFText style={styles.description}>{costValue.description}</PDFText>
      )}
      {costValue.discount_description && (
        <PDFText style={styles.discount}>{costValue.discount_description}</PDFText>
      )}
    </View>
    <PDFText style={{...styles.col3, ...styles.total}}>{getUsMoneyFormat(totalActual)}</PDFText>
  </View>
);

export const TotalRow: FC<{label: string; value: number}> = ({label, value}) => (
  <View style={styles.totalRow}>
    <PDFText fontFamily="Helvetica700" style={styles.totalLabel}>
      {label}
    </PDFText>
    <PDFText fontFamily="Helvetica700" style={styles.totalValue}>
      {getUsMoneyFormat(value)}
    </PDFText>
  </View>
);

const styles = StyleSheet.create({
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    textTransform: "uppercase",
    paddingBottom: pxToPt(4),
    borderBottom: "2px solid black",
    marginBottom: pxToPt(16)
  },
  col1: {width: "9%"},
  col2: {width: "71%"},
  col3: {width: "20%"},
  headerCode: {fontSize: pxToPt(20)},
  headerName: {fontSize: pxToPt(20)},
  headerTotal: {fontSize: pxToPt(16), marginLeft: "auto"},
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: pxToPt(8)
  },
  code: {fontSize: pxToPt(16)},
  name: {fontSize: pxToPt(16)},
  description: {fontSize: pxToPt(16)},
  discount: {fontSize: pxToPt(16)},
  total: {fontSize: pxToPt(16), textAlign: "right"},
  totalRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: pxToPt(16)
  },
  totalLabel: {fontSize: pxToPt(19), textTransform: "uppercase", width: "33%"},
  totalValue: {fontSize: pxToPt(19), textTransform: "uppercase", textAlign: "right", width: "20%"}
});
