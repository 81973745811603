type tOption = {
  value: string;
  label: string;
};
type Item = any & tOption;
export const transformDataToOptions = (data?: Item[]) => {
  if (!data) return [];
  return data.map(({id, name}: {id: number; name: string}) => ({
    value: String(id),
    label: name
  }));
};
export const transformArrayForSelect = (data: any): tOption[] => {
  if (!data || !data.data) return [];
  return data.data.data.map(({id, name}: {id: number; name: string}) => ({
    value: String(id),
    label: name
  }));
};
export const toBase64 = (file: File | null) =>
  new Promise<ArrayBuffer | string | null>((resolve, reject) => {
    if (!file) return null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const blobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export function pxToPt(px: number) {
  const val = px * 0.75;
  return `${val}pt`;
}
