import {View, StyleSheet} from "@react-pdf/renderer";
import {getUsMoneyFormat} from "helpers/formattingData";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";

type tProductionCostsRowsProps = {
  code: string;
  name: string;
  total: number;
};

export const ProductionCostsRow = ({code, name, total}: tProductionCostsRowsProps) => {
  return (
    <View style={styles.row} wrap={false}>
      <View style={styles.code}>
        <PDFText>{code}</PDFText>
      </View>
      <View style={styles.name}>
        <PDFText fontFamily="Helvetica500">{name}</PDFText>
      </View>
      <View style={styles.total}>
        <PDFText>{getUsMoneyFormat(total)}</PDFText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: pxToPt(2)
  },
  code: {
    width: pxToPt(60),
    flexShrink: 0,
    fontSize: pxToPt(12)
  },
  name: {
    flexGrow: 1,
    fontSize: pxToPt(12)
  },
  total: {
    flexShrink: 0,
    textAlign: "right",
    fontSize: pxToPt(12)
  }
});
