import {StyleSheet} from "@react-pdf/renderer";
import {tInvoice} from "api/invoice/types";
import {getUsMoneyFormat} from "helpers/formattingData";
import {pxToPt} from "helpers/transform";

type ListItem = {
  label: string;
  value: string;
  className?: {[key: string]: string};
};

export const prepareInvoiceTotalData = (invoice: tInvoice): ListItem[] => {
  return [
    {label: "Actual Production Costs", value: getUsMoneyFormat(invoice.actual_production_costs)},
    {
      label: "Production % on Budgeted Expenses",
      value: getUsMoneyFormat(invoice.production),
      className: styles.offsetLeft
    },
    {
      label: "Insurance % on Budgeted Expenses",
      value: getUsMoneyFormat(invoice.insurance),
      className: styles.offsetLeft
    },
    {
      label: "Green",
      value: getUsMoneyFormat(invoice.green),
      className: {...styles.greenText, ...styles.offsetLeft}
    },
    {label: "Sales Tax", value: getUsMoneyFormat(invoice.sales_tax), className: styles.offsetLeft},
    ...invoice.advances.flatMap(entry => [
      {
        label: `Advance Payment on Invoice No. ${entry.invoice_number}`,
        value: getUsMoneyFormat(entry.amount_required)
      },
      ...(entry.late_payment_fees
        ? [
            {
              label: `Late Payment Fee, ${entry.late_payment_days} days @ ${getUsMoneyFormat(
                entry.late_payment_daily
              )}`,
              value: getUsMoneyFormat(entry.late_payment_amount),
              className: styles.offsetLeft
            }
          ]
        : [])
    ]),
    {label: "Final Adjustments", value: getUsMoneyFormat(invoice.final_adjustment)}
  ];
};

const styles = StyleSheet.create({
  offsetLeft: {
    paddingLeft: pxToPt(16)
  },
  greenText: {color: "#029457"}
});
