import {useState, FormEvent, useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {iJobDetailsDataReturn, iUpdateJobDetailsParams} from "api/jobs/types";
import {jobsApi} from "api/jobs";
import {tags as tagsApi} from "api/tags";
import {titles as titlesApi} from "api/titles";
import {contactsApi} from "api/contacts";
import {locationApi} from "api/location";
import {team as teamApi} from "api/team";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useTags} from "hooks/inputHooks";
import {useJobTagsField} from "./JobTagsField/useJobTagsField";
import {companies as companiesApi} from "api/contacts/companies";
import {useSearchField} from "hooks/useSearchField";
// import {useDateRangePicker} from "hooks/useDateRangePicker";
import {useDateRangePickerV1} from "hooks/useDateRangePickerV1";
import {useJobContactsField} from "./JobContactsField/useJobContactsField";
import {useJobArtistField} from "./JobArtistField/useJobArtistField";
import {notValidForm} from "helpers/misc";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {eventBus} from "EventBus/EventBus";
import {iTag} from "api/tags/interfaces";
import {useNavigate} from "react-router-dom";

interface iEditJobDetailsProps {
  job: iJobDetailsDataReturn | number;
  onCloseCallback: () => void;
}

export function useEditJobDetails({job, onCloseCallback}: iEditJobDetailsProps) {
  const navigate = useNavigate();
  const {call} = useHttp();
  const {setJobDetails} = useContextJobDetails();
  const {call: callUpdateJob, isLoading: isUpdatingJob} = useHttp();
  const deleteQuery = useHttp();
  const {data: localesData} = useHttp({config: tagsApi.locales.get(), onMountCall: true});
  const {data: titles} = useHttp({config: titlesApi.get(), onMountCall: true});
  const [details, setDetails] = useState<iJobDetailsDataReturn | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const formData = {
    number: useTextInput({value: String(details?.id)}),
    name: useTextInput({isRequired: true, value: details?.name}),
    client: useSearchField({
      required: true,
      searchConfig: contactsApi?.searchContacts,
      searchKey: "query",
      value: details?.client
    }),
    ad_agency_id: useSearchField({
      required: false,
      searchConfig: companiesApi.getList,
      searchKey: "search",
      value: details?.ad_agency
    }),
    locales: useTags({value: details?.locales, isRequired: false}),
    dates: useDateRangePickerV1({startDate: details?.start_at, endDate: details?.end_at}),
    people: useJobContactsField({
      titles: titles?.data?.data || [],
      values: details?.people,
      required: false
    }),
    artists: useJobArtistField({values: details?.artists, required: false}),
    locations: useJobTagsField({
      apiConfig: locationApi?.locations?.get,
      required: false,
      searchKey: "search"
    }),
    team: useJobTagsField({
      apiConfig: teamApi.getTeamList,
      required: false,
      searchKey: "search"
    }),
    description: useTextInput({isRequired: false}),
    notes: useTextInput({isRequired: false})
  };

  useEffect(() => {
    if (typeof job === "number") {
      call(jobsApi.getJobDetails(job)).then(({data: {data}}) => setDetails(data));
      return;
    }
    setDetails(job);
  }, [job]); // eslint-disable-line

  useEffect(() => {
    if (!details) return;
    formData.number.setValue(String(details.id));
    formData.name.setValue(details.name);
    formData.locales.onChange(details.locales);
    formData.locations.onChange(details.locations);
    formData.team.onChange(details.team);
    details?.description && formData.description.setValue(details.description);
    details?.notes && formData.notes.setValue(details.notes);
  }, [details]); // eslint-disable-line

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (notValidForm(formData, true) || !details?.id) return;

    const {ad_agency_id, name, artists, dates, people, locales, client} = formData;
    const {locations, team, notes, description} = formData;

    const dateToUnixTimestamp = (date: Date | null): number => {
      if (!date) return 0;
      return Math.floor(date.getTime() / 1000);
    };

    const preparedFormData: iUpdateJobDetailsParams = {
      name: name.value,
      client_type: client.value.type || details?.client_type,
      client_id: client.value.id,
      start_at: dateToUnixTimestamp(dates.startDate),
      end_at: dateToUnixTimestamp(dates.endDate),
      ...(ad_agency_id.value?.id && {ad_agency_id: ad_agency_id.value.id}),
      locales: locales.value.map(({id}) => +id),
      people: people.values.map(item => ({
        person_id: Number(item?.person?.id),
        is_lead: Boolean(item.lead?.value),
        ...(item?.title?.id && {title_id: Number(item?.title?.id)})
      })),
      artists: artists.values.map(item => ({
        ...(item?.person?.id && {artist_id: Number(item?.person?.id)}),
        ...(item?.agent?.id && {agent_id: Number(item?.agent?.id)}),
        ...(item?.agency?.id && {artist_agency_id: Number(item?.agency?.id)}),
        ...(item?.title?.id && {title_id: Number(item?.title?.id)})
      })),
      locations: locations?.value?.map(({id}) => +id),
      team: team?.value?.map(({id}) => +id),
      description: description.value,
      notes: notes.value
    };

    try {
      const response = await callUpdateJob(jobsApi.updateJobDetails(details?.id, preparedFormData));
      eventBus.dispatch("showToast", {text: response?.data?.message});
      onCloseCallback();
      setJobDetails(response.data.data);
      onCloseCallback();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error, formData); // eslint-disable-line
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onDelete = async () => {
    if (!details?.id) return;
    try {
      const {
        data: {message}
      } = await deleteQuery.call(jobsApi.deleteJob(details.id));
      eventBus.dispatch("showToast", {text: message});
      navigate("/dashboard/overview");
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    localesData: localesData?.data?.data as Array<iTag>,
    formData,
    details,
    handleSubmit,
    isUpdatingJob,
    onDelete,
    confirmDelete,
    setConfirmDelete,
    deleteQuery,
    titles: titles?.data?.data || []
  };
}
