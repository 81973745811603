import {View, StyleSheet} from "@react-pdf/renderer";
import {tEstimateExportInfo} from "api/budget/estimate/types";
import {getDateRange} from "helpers/dates";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {InfoBlockContainer} from "./_InfoBlockContainer";
import {tAdvanceDetails} from "api/budget/advance/types";
import {tInvoice} from "api/invoice/types";
import {iJobDetailsDataReturn} from "api/jobs/types";

type tJobDescriptionProps = {
  data: tEstimateExportInfo | tAdvanceDetails | tInvoice;
  jobData: iJobDetailsDataReturn;
};

export const JobDescription = ({data, jobData}: tJobDescriptionProps) => {
  const date = getDateRange({
    start_at: data.job_start_at || new Date(),
    end_at: data.job_end_at || new Date(),
    monthFormat: "MMMM"
  });

  const locationsTitle = "shoot Location";
  const locals = jobData.locales?.map(item => item.name)?.join(", ");

  let dataMap = [
    {
      label: "Job Name",
      value: data.job_name || ""
    },
    {
      label: "Job number",
      value: data.job_number || ""
    },
    {
      label: "PHOTOGRAPHER",
      value: data.photographer || ""
    },
    {
      label: "PO",
      value: data.po || ""
    },
    {
      label: locationsTitle,
      value: locals
    },
    {
      label: "shoot Dates",
      value: `${date.periodStart}-${date.periodEnd}, ${new Date(
        data.job_end_at || ""
      ).getFullYear()}`
    },
    {
      label: data.extended?.[0]?.label,
      value: data.extended?.[0]?.text
    },
    {
      label: data.extended?.[1]?.label,
      value: data.extended?.[1]?.text
    }
  ];

  return (
    <InfoBlockContainer title="Job description">
      {dataMap.map((item, i) => {
        if (!item?.value) return null;
        return (
          <View key={i} style={styles.row}>
            <View style={styles.labelContainer}>
              <PDFText fontFamily="Helvetica700" style={styles.label}>
                {item.label}
              </PDFText>
            </View>
            <View style={styles.valueContainer}>
              <PDFText style={styles.value}>{item.value}</PDFText>
            </View>
          </View>
        );
      })}
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: pxToPt(4)
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: pxToPt(4)
  },
  labelContainer: {
    width: "36%",
    paddingRight: pxToPt(6)
  },
  valueContainer: {
    flex: 1
  },
  label: {
    textTransform: "uppercase",
    fontSize: pxToPt(13)
  },
  value: {
    fontSize: pxToPt(13)
  }
});
