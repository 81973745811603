import {FC} from "react";
import ReactPDF, {StyleSheet, Text, Font} from "@react-pdf/renderer";
import Helvetica700 from "assets/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf";
import Helvetica500 from "assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf";
import Helvetica400 from "assets/fonts/HelveticaNeue/HelveticaNeue.ttf";

Font.register({
  family: "Helvetica500",
  src: Helvetica500
});
Font.register({
  family: "Helvetica400",
  src: Helvetica400
});
Font.register({
  family: "Helvetica700",
  src: Helvetica700
});

type tPDFTextProps = ReactPDF.TextProps & {
  fontFamily?: "Helvetica500" | "Helvetica400" | "Helvetica700";
};

export const PDFText: FC<tPDFTextProps> = props => {
  const {children, style, fontFamily = "Helvetica400", ...rest} = props;

  const mergedStyle = StyleSheet.create({
    text: {
      fontFamily,
      ...style
    }
  });
  return (
    <Text style={mergedStyle.text} {...rest}>
      {children}
    </Text>
  );
};
