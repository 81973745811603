import {FC} from "react";
import {StyleSheet, View} from "@react-pdf/renderer";
import {tScheduleRow} from "api/callSheet/types";
import {SectionTitle} from "./SectionTitle";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";

type tScheduleProps = {
  scheduleList: Array<tScheduleRow>;
};

export const Schedule: FC<tScheduleProps> = ({scheduleList}) => {
  const isEmpty = !scheduleList.filter(item => item?.some(row => row.time || row.text))?.length;
  if (isEmpty) return null;

  return (
    <View style={styles.wrap}>
      {scheduleList?.map((item, i) => {
        if (i !== 0) return <ScheduleRow rowData={item} key={i} />;

        return (
          <View key={i} wrap={false}>
            <SectionTitle>Schedule</SectionTitle>
            <ScheduleRow rowData={item} />
          </View>
        );
      })}
    </View>
  );
};

type tScheduleRowProps = {
  rowData: tScheduleRow;
};

export const ScheduleRow: FC<tScheduleRowProps> = ({rowData}) => {
  const col1 = rowData?.[0];
  const col2 = rowData?.[1];

  return (
    <View wrap={false} style={styles.row}>
      <PDFText fontFamily="Helvetica700" style={styles.colTime}>
        {col1?.time}
      </PDFText>
      <PDFText style={styles.colText}>{col1?.text}</PDFText>
      <PDFText fontFamily="Helvetica700" style={styles.colTime}>
        {col2?.time}
      </PDFText>
      <PDFText style={{...styles.colText, paddingRight: 0}}>{col2?.text}</PDFText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {marginBottom: pxToPt(48)},
  row: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: pxToPt(8)
  },
  colTime: {
    width: "17.5%",
    fontSize: pxToPt(15),
    paddingRight: 8
  },
  colText: {
    width: "32.5%",
    fontSize: pxToPt(15),
    paddingRight: 8
  }
});
