import {FC} from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";
import {tCallSheetContact} from "api/callSheet/types";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";

type tContactsRowsProps = {
  contact: tCallSheetContact;
};

export const ContactsRows: FC<tContactsRowsProps> = ({contact}) => {
  const isManuallyEntered = typeof contact.id === "string";
  const showAgentRow = !!contact?.agent;
  return (
    <View wrap={false}>
      <View style={styles.listRowItem} wrap={false}>
        <PDFText fontFamily="Helvetica700" style={{...styles?.regularCol, ...styles.colName}}>
          {contact?.name}
        </PDFText>
        <PDFText style={{...styles?.regularCol, ...styles.colRegText}}>{contact?.position}</PDFText>
        <PDFText style={{...styles?.regularCol, ...styles.colRegText}}>
          {contact?.email?.show || isManuallyEntered ? contact?.email?.value : ""}
        </PDFText>
        <PDFText style={{...styles?.phoneCol, ...styles.colRegText}}>
          {contact?.phone?.show || isManuallyEntered ? contact?.phone?.value : ""}
        </PDFText>
        <PDFText style={{...styles?.timeCol, ...styles.colRegText}}>{contact?.time}</PDFText>
        <PDFText style={{...styles?.regularCol, ...styles.colRegText, marginRight: 0}}>
          {contact?.location?.name}
        </PDFText>
      </View>
      {showAgentRow && (
        <View style={styles.listRowItem} wrap={false}>
          <PDFText fontFamily="Helvetica700" style={{...styles?.regularCol, ...styles.colName}}>
            &bull; {contact?.agent?.name}
          </PDFText>
          <PDFText style={{...styles?.regularCol, ...styles.colRegText}}>
            {contact?.agent?.position}
          </PDFText>
          <PDFText style={{...styles?.regularCol, ...styles.colRegText}}>
            {contact?.agent?.email?.value}
          </PDFText>
          <PDFText style={{...styles?.phoneCol, ...styles.colRegText}}>
            {contact?.agent?.phone?.value}
          </PDFText>
          <Text style={styles?.timeCol} />
          <Text style={{...styles?.regularCol, marginRight: 0}} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  listRowItem: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: pxToPt(8)
  },
  colName: {
    fontSize: pxToPt(17)
  },
  colRegText: {
    fontSize: pxToPt(14)
  },
  regularCol: {
    width: "19%",
    marginRight: 6
  },
  phoneCol: {
    width: "14%",
    marginRight: 6
  },
  timeCol: {
    width: "9%",
    marginRight: 6
  }
});
