import {View, StyleSheet} from "@react-pdf/renderer";
import {SectionTitle} from "../SectionTitle";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat} from "helpers/formattingData";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";
import {ProductionCostsRow} from "./ProductionCosts.Row";

type tProductionCosts = {
  title: string;
  summaryTitle: string;
  data: tEstimateData;
};

export const ProductionCosts = ({title, summaryTitle, data}: tProductionCosts) => {
  const list = [
    ...data.category_values,
    {id: "01", code: "", name: "Crew Payroll Fee", total: data?.crew_payroll_fee},
    {id: "02", code: "", name: "Talent Payroll Fee", total: data?.talent_payroll_fee},
    {id: "03", code: "", name: "Union Crew Payroll Fee", total: data?.union_crew_payroll_fee},
    {id: "04", code: "", name: "Union Talent Payroll Fee", total: data?.union_talent_payroll_fee},
    {id: "05", code: "", name: "Insurance", total: data?.insurance}
  ];

  return (
    <View style={styles.container}>
      {list.map(({id, name, code, total}, i) => {
        const isFirst = i === 0;
        const isLast = i === list.length - 1;

        if (isFirst) {
          return (
            <View wrap={false} key={id}>
              <SectionTitle>{title}</SectionTitle>
              <ProductionCostsRow code={code} name={name} total={total} />
            </View>
          );
        }

        if (isLast) {
          return (
            <View wrap={false} key={id}>
              <ProductionCostsRow code={code} name={name} total={total} />

              <View style={styles.totalEstimate} wrap={false}>
                <PDFText fontFamily="Helvetica700" style={styles.totalEstimateText}>
                  {summaryTitle}
                </PDFText>
                <PDFText fontFamily="Helvetica700" style={styles.totalEstimateText}>
                  {getUsMoneyFormat(data.budgeted_costs)}
                </PDFText>
              </View>
            </View>
          );
        }

        return <ProductionCostsRow key={id} code={code} name={name} total={total} />;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: pxToPt(16)
  },
  totalEstimate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: pxToPt(8)
  },
  totalEstimateText: {
    fontSize: pxToPt(14),
    paddingLeft: pxToPt(50),
    textTransform: "uppercase"
  }
});
