import {View, StyleSheet} from "@react-pdf/renderer";
import {PDFText} from "components/PDFText";
import {getUsMoneyFormat} from "helpers/formattingData";
import {pxToPt} from "helpers/transform";

export const EstimateValuesRowTotal = ({total}: {total: number}) => {
  const {row, colTotal, colNumber, text} = styles;
  return (
    <View style={row} wrap={false}>
      <View style={colTotal}>
        <PDFText fontFamily="Helvetica700" style={text}>
          Total
        </PDFText>
      </View>
      <View style={colNumber}>
        <PDFText fontFamily="Helvetica700" style={text}>
          {getUsMoneyFormat(total)}
        </PDFText>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: pxToPt(12)
  },
  colTotal: {paddingRight: pxToPt(20)},
  colNumber: {
    width: "37%",
    textAlign: "right"
  },
  text: {
    fontSize: pxToPt(15),
    textTransform: "uppercase"
  }
});
