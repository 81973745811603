import {FC} from "react";
import ReactPDF, {View, StyleSheet} from "@react-pdf/renderer";
import {pxToPt} from "helpers/transform";

interface iTwoColumnWrapProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  wrap?: boolean;
  style?: ReactPDF.ViewProps["style"];
}

const gap = pxToPt(16);

export const TwoColumnWrap: FC<iTwoColumnWrapProps> = ({
  leftChildren,
  rightChildren,
  wrap = true,
  style
}) => {
  return (
    <View style={{...styles.container, ...style}} wrap={wrap}>
      <View style={{...styles.column, paddingRight: gap}}>{leftChildren}</View>
      <View style={{...styles.column, paddingLeft: gap}}>{rightChildren}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 1 //don't remove this, need when all children are empty
  },
  column: {
    flex: 1
  }
});
