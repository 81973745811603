import {StyleSheet} from "@react-pdf/renderer";
import {pxToPt} from "helpers/transform";
import {InfoBlockContainer} from "./_InfoBlockContainer";
import {PDFText} from "components/PDFText";

export const PayTo = () => {
  return (
    <InfoBlockContainer title="Pay to">
      <PDFText style={styles.text}>Versatile Studios</PDFText>
      <PDFText style={styles.text}>+1 212-685-4384</PDFText>
      <PDFText style={styles.text}>346 Park Avenue S, Fl 4, New York, NY 10010</PDFText>
      <PDFText style={styles.text}>www.versatilestudios.com</PDFText>
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: pxToPt(13),
    marginBottom: pxToPt(4)
  }
});
