import {View, StyleSheet} from "@react-pdf/renderer";
import {SectionTitle} from "../SectionTitle";
import {getUsMoneyFormat} from "helpers/formattingData";
import {pxToPt} from "helpers/transform";
import {PDFText} from "components/PDFText";
import {ProductionCostsRow} from "./ProductionCosts.Row";
import {tTrack} from "api/budget/track/types";

type tProductionCosts = {
  title: string;
  summaryTitle: string;
  data: tTrack;
};

export const ProductionCostsTrack = ({title, summaryTitle, data}: tProductionCosts) => {
  const fees = data?.payroll_fees;

  const list = [
    ...data.categories,
    {
      category: {id: "01", code: "", name: "Crew Payroll Fee"},
      category_total: {total_actual: fees?.crew_payroll_fee?.total_actual}
    },
    {
      category: {id: "02", code: "", name: "Talent Payroll Fee"},
      category_total: {total_actual: fees?.talent_payroll_fee?.total_actual}
    },
    {
      category: {id: "03", code: "", name: "Union Crew Payroll Fee"},
      category_total: {total_actual: fees?.union_crew_payroll_fee?.total_actual}
    },
    {
      category: {id: "04", code: "", name: "Union Talent Payroll Fee"},
      category_total: {total_actual: fees?.union_talent_payroll_fee?.total_actual}
    }
  ];

  return (
    <View style={styles.container}>
      {list.map(({category, category_total}, i) => {
        const {id, code, name} = category;
        const {total_actual} = category_total;
        const isFirst = i === 0;
        const isLast = i === list.length - 1;

        if (isFirst) {
          return (
            <View wrap={false} key={id}>
              <SectionTitle>{title}</SectionTitle>
              <ProductionCostsRow code={code} name={name} total={total_actual} />
            </View>
          );
        }

        if (isLast) {
          return (
            <View wrap={false} key={id}>
              <ProductionCostsRow code={code} name={name} total={total_actual} />

              <View style={styles.totalEstimate} wrap={false}>
                <PDFText fontFamily="Helvetica700" style={styles.totalEstimateText}>
                  {summaryTitle}
                </PDFText>
                <PDFText fontFamily="Helvetica700" style={styles.totalEstimateText}>
                  {/* {getUsMoneyFormat(data?.grand_total?.total_actual)} */}
                  {getUsMoneyFormat(data?.total?.total_actual)}
                </PDFText>
              </View>
            </View>
          );
        }

        return <ProductionCostsRow key={id} code={code} name={name} total={total_actual} />;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: pxToPt(16)
  },
  totalEstimate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: pxToPt(8)
  },
  totalEstimateText: {
    fontSize: pxToPt(14),
    paddingLeft: pxToPt(50),
    textTransform: "uppercase"
  }
});
