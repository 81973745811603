import {FC} from "react";

import {StyleSheet, View, Image} from "@react-pdf/renderer";
import logo from "assets/images/logo_text.png";
import logoProduction from "assets/images/LogoProduction.png";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";

type iHeader = {
  text: string;
  type?: "sm" | "md";
  typeLogo?: "new" | "old";
};

export const Header: FC<iHeader> = ({text, type = "md", typeLogo = "old"}) => {
  const styles = StyleSheet.create({
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: typeLogo === "new" ? pxToPt(24) : pxToPt(type === "sm" ? 24 : 48)
    },
    columnLeft: {
      width: "60%",
      paddingRight: 10
    },
    logoTitle: {
      maxWidth: type === "sm" ? "75%" : "100%"
    },
    newLogoTitle: {
      maxWidth: "45%"
    },
    columnRight: {
      flexShrink: 0,
      alignSelf: "flex-start"
    },
    title: {
      fontSize: pxToPt(type === "sm" ? 18 : 22),
      textTransform: "uppercase"
    }
  });

  const logoImage = typeLogo === "new" ? logoProduction : logo;
  const logoStyles = typeLogo === "new" ? styles?.newLogoTitle : styles?.logoTitle;
  return (
    <View style={styles.header}>
      <View style={styles.columnLeft}>
        <Image style={logoStyles} src={logoImage} />
      </View>
      <View style={styles.columnRight}>
        <PDFText fontFamily="Helvetica700" style={styles.title}>
          {text}
        </PDFText>
      </View>
    </View>
  );
};
