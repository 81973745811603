import {useLocation} from "react-router-dom";
import {MouseEvent, useEffect, useState} from "react";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {useNavigate} from "react-router-dom";

type tModal = "rename" | "new" | "delete" | "duplicate" | "dropboxAuth";
type tExport = "saveToDropbox" | "download";

export function useCallSheetHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const isTrack = location.pathname.includes("track");
  const {callSheetList, deliveryInfo, callSheetInfo, isLoading} = useContextJobCallSheet(); // prettier-ignore

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [burgerAnchor, setBurgerAnchor] = useState<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState<tModal | null>(null);
  const [exporting] = useState<tExport | null>(null);

  const closeModal = () => setShowModal(null);

  useEffect(() => {
    if (!exporting) setBurgerAnchor(null);
  }, [exporting]);

  const showSelectSheetMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.name === "burger"
      ? setBurgerAnchor(event.currentTarget)
      : setAnchor(event.currentTarget);
  };

  const onChangeActiveSheet = (id: string) => {
    setAnchor(null);
    navigate(String(id));
  };

  const onShowModal = (type: tModal) => {
    setAnchor(null);
    setBurgerAnchor(null);
    setShowModal(type);
  };

  // const onExport = (type: tExport) => {
  //   setExporting(type);
  // };

  return {
    burgerAnchor,
    anchor,
    showModal,
    onShowModal,
    onChangeActiveSheet,
    showSelectSheetMenu,
    isTrack,
    closeModal,
    callSheetList,
    callSheetInfo,
    setShowModal,
    setAnchor,
    setBurgerAnchor,
    isLoading,
    // exporting,
    // setExporting,
    // onExport,
    deliveryInfo
  };
}
