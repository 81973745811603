import {FC} from "react";
import ReactPDF, {StyleSheet, View} from "@react-pdf/renderer";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";

type tSectionTitleProps = ReactPDF.TextProps;

export const SectionTitle: FC<tSectionTitleProps> = props => {
  const {children, style, ...rest} = props;
  const mergedStyle = StyleSheet.create({
    text: {
      fontSize: pxToPt(16),
      textTransform: "uppercase",
      paddingBottom: pxToPt(3),
      borderBottom: "2px solid black",
      ...style
    }
  });

  return (
    <View style={{marginBottom: pxToPt(3)}} wrap={false}>
      <PDFText fontFamily="Helvetica700" style={mergedStyle.text} {...rest}>
        {children}
      </PDFText>
    </View>
  );
};
