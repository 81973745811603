import {FC} from "react";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import {View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {pxToPt} from "helpers/transform";
import {ContactsRows} from "./Contacts.Rows";

type tContactsProps = {
  contactsGroup: tCallSHeetGroupContacts[];
};

export const Contacts: FC<tContactsProps> = ({contactsGroup}) => {
  const filteredGroups = contactsGroup?.filter(group =>
    group.contacts.some(contact => contact.selected && typeof contact.id === "number")
  );

  return (
    <>
      {filteredGroups?.map(group => {
        const filteredContacts = group?.contacts?.filter(item => item.selected && typeof item.id === "number"); // prettier-ignore
        return (
          <View key={group?.id} style={{paddingBottom: pxToPt(32)}}>
            {filteredContacts?.map((item, i) => {
              if (i !== 0) return <ContactsRows key={item?.id} contact={item} />;
              return (
                <View key={item?.id} wrap={false}>
                  <SectionTitle>{group?.name}</SectionTitle>
                  <ContactsRows key={item?.id} contact={item} />;
                </View>
              );
            })}
          </View>
        );
      })}
    </>
  );
};
