import {FC} from "react";
import {Page, Document, StyleSheet, View} from "@react-pdf/renderer";
import {Header} from "../../PdfTemplates/letterUS/Header";
import {Footer as FooterA2} from "../../PdfTemplates/a2/Footer";
import {HeaderBreakdown as HeaderBreakdownA2} from "../../PdfTemplates/a2/HeaderBreakdown";
import {EstimateValuesTable as EstimateValuesTableA2} from "../../PdfTemplates/a2/EstimateValuesTable";

import {iJobDetailsDataReturn} from "api/jobs/types";
import {tEstimateExportInfo} from "api/budget/estimate/types";
import {pxToPt} from "helpers/transform";
import {TwoColumnWrap} from "../../PdfTemplates/letterUS/TwoColumnWrap";
import {Client} from "../../PdfTemplates/letterUS/infoBlocks/Client";
import {JobDescription} from "../../PdfTemplates/letterUS/infoBlocks/JobDescription";
import {ProductionCosts} from "../../PdfTemplates/letterUS/tables/ProductionCosts";
import {Confirmation} from "../../PdfTemplates/letterUS/infoBlocks/Confirmation";
import {Totals} from "../../PdfTemplates/letterUS/infoBlocks/Totals";
import {PayTo} from "../../PdfTemplates/letterUS/infoBlocks/PayTo";
import {TextBlock} from "../../PdfTemplates/letterUS/infoBlocks/TextBlock";
import {EstimateValues} from "../../PdfTemplates/letterUS/tables/EstimateValues";

const styles = StyleSheet.create({
  letter: {
    padding: pxToPt(35)
  },
  A2: {
    padding: 80,
    width: "100%",
    height: "100%"
  }
});

interface tEstimateOverlayDocumentProps {
  jobDetailsData: iJobDetailsDataReturn;
  exportInfo: tEstimateExportInfo;
  format?: "A4" | "A2";
}

export const EstimateOverlayDocument: FC<tEstimateOverlayDocumentProps> = ({
  exportInfo,
  jobDetailsData,
  format = "A4"
}) => {
  return (
    <Document>
      {format === "A4" && (
        <Page size="LETTER" style={styles.letter}>
          <View wrap={false}>
            <Header text="Estimate summary" type="sm" />

            <TwoColumnWrap
              leftChildren={<Client client={jobDetailsData.client} />}
              rightChildren={<PayTo />}
            />

            <TwoColumnWrap
              leftChildren={<JobDescription data={exportInfo} jobData={jobDetailsData} />}
              rightChildren={<Totals estimate={exportInfo.estimate} />}
            />

            <ProductionCosts
              title="Estimated production costs"
              summaryTitle="ESTIMATED PRODUCTION COSTS"
              data={exportInfo.estimate}
            />
          </View>

          <TextBlock title="Notes" text={exportInfo?.notes} />
          <TextBlock title="Creative" text={exportInfo?.creative} />
          <TextBlock title="Usage license" text={exportInfo?.usage_license} />

          <TwoColumnWrap
            wrap={false}
            leftChildren={<Confirmation clientName={jobDetailsData.client?.name || ""} />}
          />

          <View break>
            <Header text="Estimate breakdown" type="md" />
            <EstimateValues data={exportInfo.estimate.category_values || []} />
          </View>
        </Page>
      )}
      {format === "A2" && (
        <>
          <Page size="A2" style={styles.A2}>
            <HeaderBreakdownA2
              jobName={exportInfo.job_name}
              jobNumber={exportInfo.job_number}
              photographer={exportInfo.photographer}
              po={exportInfo.po}
              title="ESTIMATE BREAKDOWN"
            />
            <EstimateValuesTableA2 data={exportInfo.estimate.category_values || []} />
            <FooterA2 />
          </Page>
        </>
      )}
    </Document>
  );
};
