import {View, StyleSheet} from "@react-pdf/renderer";
import {tInvoice} from "api/invoice/types";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {prepareInvoiceTotalData} from "./InvoiceTotals.PrepareData";
import {SectionTitle} from "../SectionTitle";
import {getUsMoneyFormat} from "helpers/formattingData";

type tInvoiceTotalsProps = {
  invoice: tInvoice;
};

export const InvoiceTotals = ({invoice}: tInvoiceTotalsProps) => {
  const list = prepareInvoiceTotalData(invoice);

  return (
    <View style={styles.container}>
      {list.map((item, i) => {
        const {label, value} = item;
        const labelClass = {...styles.regularText, ...(item?.className || {})};

        const isFirst = i === 0;
        const isLast = i === list.length - 1;

        if (isFirst) {
          return (
            <View key={i} wrap={false}>
              <SectionTitle>Invoice totals</SectionTitle>
              <View style={styles.row}>
                <PDFText fontFamily="Helvetica500" style={labelClass}>
                  {label}
                </PDFText>
                <PDFText style={styles.regularText}>{value}</PDFText>
              </View>
            </View>
          );
        }

        if (isLast) {
          return (
            <View key={i} wrap={false}>
              <View style={styles.row}>
                <PDFText fontFamily="Helvetica500" style={labelClass}>
                  {label}
                </PDFText>
                <PDFText style={styles.regularText}>{value}</PDFText>
              </View>

              <View style={styles.row}>
                <PDFText
                  fontFamily="Helvetica500"
                  style={{...styles.totalLineText, paddingLeft: pxToPt(220)}}
                >
                  Invoice total
                </PDFText>
                <PDFText style={styles.totalLineText}>
                  {`USD ${getUsMoneyFormat(invoice.invoice_total)}`}
                </PDFText>
              </View>
            </View>
          );
        }

        return (
          <View style={styles.row} key={i} wrap={false}>
            <PDFText fontFamily="Helvetica500" style={labelClass}>
              {label}
            </PDFText>
            <PDFText style={styles.regularText}>{value}</PDFText>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {marginBottom: pxToPt(24)},
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8
  },
  regularText: {fontSize: pxToPt(14)},
  totalLineText: {
    fontSize: pxToPt(20),
    textTransform: "uppercase",
    fontFamily: "Helvetica700"
  }
});
