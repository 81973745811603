import {StyleSheet, View} from "@react-pdf/renderer";
import {tAdvanceDetails} from "api/budget/advance/types";
import {InfoBlockContainer} from "../infoBlocks/_InfoBlockContainer";
import {PDFText} from "components/PDFText";
import {getPercentFormat, getUsMoneyFormat} from "helpers/formattingData";
import {pxToPt} from "helpers/transform";

type tAdvanceDetailsProps = {
  data: tAdvanceDetails;
};

export const AdvanceDetails = ({data}: tAdvanceDetailsProps) => {
  return (
    <InfoBlockContainer title="Advance" wrap={false}>
      <View style={styles.row}>
        <View style={styles.col1} />
        <View style={styles.col2}>
          <PDFText fontFamily="Helvetica700" style={styles.label}>
            Budget Total
          </PDFText>
        </View>
        <View style={styles.col3}>
          <PDFText fontFamily="Helvetica700" style={styles.label}>
            Advance %
          </PDFText>
        </View>
        <View style={styles.col4}>
          <PDFText fontFamily="Helvetica700" style={{...styles.label, textAlign: "right"}}>
            Amount Required Before SHOOT
          </PDFText>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col1}>
          <PDFText fontFamily="Helvetica700" style={styles.label}>
            Total Estimate
          </PDFText>
        </View>
        <View style={styles.col2}>
          <PDFText style={styles.value}>{getUsMoneyFormat(data.budget_total)}</PDFText>
        </View>
        <View style={styles.col3}>
          <PDFText style={styles.value}>{getPercentFormat(data.advance_percent)}</PDFText>
        </View>
        <View style={styles.col4}>
          <PDFText style={{...styles.value, textAlign: "right"}}>
            {getUsMoneyFormat(data.amount_required)}
          </PDFText>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.colTotal}>
          <PDFText fontFamily="Helvetica700" style={{...styles.label, fontSize: pxToPt(18)}}>
            ADVANCE TOTAL (USD)
          </PDFText>
          <PDFText
            fontFamily="Helvetica700"
            style={{...styles.label, textAlign: "right", fontSize: pxToPt(18)}}
          >
            {getUsMoneyFormat(data.amount_required)}
          </PDFText>
        </View>
      </View>
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: pxToPt(12)
  },
  col1: {
    width: "23%"
  },
  col2: {
    width: "30%"
  },
  col3: {
    width: "27%"
  },
  col4: {
    width: "20%"
  },
  colTotal: {
    width: "57%",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: pxToPt(10),
    borderBottom: "1px solid #EDEDF0"
  },
  label: {
    fontSize: 10,
    textTransform: "uppercase"
  },
  value: {
    fontSize: 10
  }
});
