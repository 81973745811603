import {View, StyleSheet} from "@react-pdf/renderer";
import {tEstimateGroup} from "api/budget/estimate/types";
import {pxToPt} from "helpers/transform";
import {EstimateValuesHead} from "./EstimateValues.Head";
import {EstimateValuesRow} from "./EstimateValues.Row";
import {EstimateValuesRowTotal} from "./EstimateValues.RowTotal";

export type tEstimateValuesProps = {
  data: tEstimateGroup[];
};

export type tEstimateValuesCollStyles = typeof colStyles;

const gap = pxToPt(4);

const colStyles = StyleSheet.create({
  colCode: {
    padding: gap,
    width: "8%"
  },
  colName: {
    padding: gap,
    flex: 1
  },
  colQty: {
    padding: gap,
    width: "6%"
  },
  colCost: {
    padding: gap,
    width: "14%"
  },
  colTime: {
    padding: gap,
    width: "10%"
  },
  colAgency: {
    padding: gap,
    width: "9%"
  },
  colTotal: {
    padding: gap,
    width: "14%"
  }
});

export const EstimateValues = ({data}: tEstimateValuesProps) => {
  if (!data) return null;

  return (
    <View>
      {data.map(estimate => (
        <View key={estimate.id} style={{marginBottom: pxToPt(40)}}>
          {estimate.cost_values.map((item, i) => {
            const onlyOneItem = estimate.cost_values.length === 1;
            const isFirst = i === 0;
            const isLast = i === estimate.cost_values.length - 1;

            if (onlyOneItem) {
              return (
                <View key={item.id} wrap={false}>
                  <EstimateValuesHead data={estimate} collStyles={colStyles} />
                  <EstimateValuesRow data={item} collStyles={colStyles} />
                  <EstimateValuesRowTotal total={estimate.total} />
                </View>
              );
            }

            if (isFirst) {
              return (
                <View key={item.id} wrap={false}>
                  <EstimateValuesHead data={estimate} collStyles={colStyles} />
                  <EstimateValuesRow data={item} collStyles={colStyles} />
                </View>
              );
            }

            if (isLast) {
              return (
                <View key={item.id} wrap={false}>
                  <EstimateValuesRow data={item} collStyles={colStyles} />
                  <EstimateValuesRowTotal total={estimate.total} />
                </View>
              );
            }

            return <EstimateValuesRow key={item.id} data={item} collStyles={colStyles} />;
          })}
        </View>
      ))}
    </View>
  );
};
