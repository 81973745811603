import {StyleSheet} from "@react-pdf/renderer";
import {parseAddress} from "helpers/misc";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {InfoBlockContainer} from "./_InfoBlockContainer";
import {iClient} from "api/jobs/types";

type tClientProps = {client: iClient};

export const Client = ({client}: tClientProps) => {
  const {name, address} = client;
  const addressString = address ? parseAddress(address).string : "";

  return (
    <InfoBlockContainer title="Client">
      <PDFText style={styles.fontSize13}>{name}</PDFText>
      {addressString && <PDFText style={styles.fontSize13}>{addressString}</PDFText>}
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  fontSize13: {
    fontSize: pxToPt(13),
    marginBottom: pxToPt(4)
  }
});
