import {View, StyleSheet} from "@react-pdf/renderer";
import {SectionTitle} from "../SectionTitle";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";

interface iConfirmationProps {
  clientName: string;
}

export const Confirmation = ({clientName}: iConfirmationProps) => {
  return (
    <View>
      <SectionTitle>Confirmation</SectionTitle>

      <PDFText style={{...styles.text, marginBottom: pxToPt(0)}}>
        I have reviewed this estimate from Versatile Studios and
      </PDFText>
      <PDFText style={styles.text}>approve as is.</PDFText>
      <PDFText style={styles.text}>
        An advance on estimated costs is due no later than 5 days after estimate approval.
      </PDFText>

      <View style={styles.signBox} wrap={false}>
        <View style={styles.signBoxName}>
          <PDFText fontFamily="Helvetica500" style={styles.signedText}>
            Name
          </PDFText>
        </View>
        <View style={styles.signBoxDate}>
          <PDFText fontFamily="Helvetica500" style={styles.signedText}>
            Date
          </PDFText>
        </View>
        <View style={styles.signBoxLower}>
          <PDFText fontFamily="Helvetica500" style={styles.signedText}>
            Signed:
          </PDFText>
          <PDFText fontFamily="Helvetica500" style={styles.signedText}>
            For and on behalf of: {clientName}
          </PDFText>
        </View>
      </View>
    </View>
  );
};

const border = `${pxToPt(2)} solid #808080`;

const styles = StyleSheet.create({
  text: {
    fontSize: pxToPt(13),
    marginBottom: pxToPt(16)
  },
  signBox: {
    borderRadius: 5,
    border: border,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  signBoxLower: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "60px",
    padding: 4,
    borderTop: border
  },
  signBoxName: {
    width: "35%",
    height: "38px",
    padding: 4,
    borderRight: border
  },
  signBoxDate: {
    width: "65%",
    height: "38px",
    padding: 4
  },
  signedText: {
    textTransform: "uppercase",
    fontSize: pxToPt(10)
  }
});
