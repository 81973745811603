import {View, StyleSheet} from "@react-pdf/renderer";
import {PDFText} from "components/PDFText";
import {tEstimateRowData} from "api/budget/estimate/types";
import {pxToPt} from "helpers/transform";
import {getPercentFormat, getUsMoneyFormat} from "helpers/formattingData";
import {tEstimateValuesCollStyles} from "./EstimateValues";

type tEstimateValuesRowProps = {data: tEstimateRowData; collStyles: tEstimateValuesCollStyles};

export const EstimateValuesRow = ({data, collStyles}: tEstimateValuesRowProps) => {
  const {row, regularText} = styles;

  const isFlatRate = data?.time_unit?.name?.toLowerCase() === "flat rate";
  const time = isFlatRate
    ? data?.time_unit?.name
    : `${data?.time_count || ""} ${data?.time_unit?.name || ""}`;

  return (
    <View style={row} wrap={false}>
      <View style={collStyles.colCode}>
        <PDFText style={regularText}>{data?.code + data?.suffix}</PDFText>
      </View>
      <View style={collStyles.colName}>
        <PDFText fontFamily="Helvetica500" style={regularText}>
          {data?.name}
        </PDFText>
        {!!data?.description && (
          <PDFText
            fontFamily="Helvetica500"
            style={{...regularText, color: "#7E7E80", marginTop: pxToPt(4)}}
          >
            {data?.description}
          </PDFText>
        )}
        {!!data?.discount_description && (
          <PDFText
            fontFamily="Helvetica500"
            style={{...regularText, color: "#D2232A", marginTop: pxToPt(4)}}
          >
            {data?.discount_description}
          </PDFText>
        )}
      </View>
      <View style={collStyles.colQty}>
        <PDFText style={{...regularText, textAlign: "center"}}>{data?.quantity}</PDFText>
      </View>
      <View style={collStyles.colCost}>
        <PDFText style={regularText}>
          {getUsMoneyFormat(data?.cost || 0, data?.currency_rate?.code)}
        </PDFText>
      </View>
      <View style={collStyles.colTime}>
        <PDFText style={regularText}>{time}</PDFText>
      </View>
      <View style={collStyles.colAgency}>
        <PDFText style={{...regularText, textAlign: "center"}}>
          {data?.agency_percent ? `${getPercentFormat(data?.agency_percent)}` : ""}
        </PDFText>
      </View>
      <View style={collStyles.colTotal}>
        <PDFText style={{...regularText, textAlign: "right"}}>
          {getUsMoneyFormat(data?.total || 0)}
        </PDFText>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: pxToPt(2)
  },
  regularText: {
    fontSize: pxToPt(14)
  }
});
