import {tCallSheetInfo} from "api/callSheet/types";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {prepareName} from "helpers/fileNaming";
import moment from "moment";

export function getSheetsWithVisibleGroups(sheets: tCallSheetInfo | null) {
  if (!sheets) return null;
  return {
    ...sheets,
    attributes: {
      ...sheets?.attributes,
      groups: sheets?.attributes?.groups?.filter(v => !v.hidden)
    }
  };
}

export const getPdfName = ({
  jobDetailsData,
  proofedCallSheetInfo
}: {
  jobDetailsData: iJobDetailsDataReturn | null;
  proofedCallSheetInfo: tCallSheetInfo | null;
}) => {
  const name = prepareName(
    [
      jobDetailsData?.name || "",
      jobDetailsData?.artists?.[0]?.artist?.name?.split(" ")?.join("") || "",
      moment(proofedCallSheetInfo?.date).format("YYYYMMDD") || "",
      proofedCallSheetInfo?.name || ""
    ],
    "_CS.pdf"
  );

  return name;
};
