import {FC} from "react";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {tCallSheetInfo} from "api/callSheet/types";
import {getName} from "helpers/misc";
import {getDateFormat} from "helpers/dates";
import {Page, Document, StyleSheet} from "@react-pdf/renderer";
import {Header} from "./pdfSections/Header";
import {Locations} from "./pdfSections/Locations";
import {Schedule} from "./pdfSections/Schedule";
import {Contacts} from "./pdfSections/Contacts";
import {pxToPt} from "helpers/transform";
import "./PDFTemplate.scss";

type tPDFTemplate = {
  id?: string;
  jobDetailsData: iJobDetailsDataReturn | null;
  callSheetInfo: tCallSheetInfo | null;
};

export const PDFTemplate: FC<tPDFTemplate> = ({callSheetInfo, jobDetailsData}) => {
  const contactsList = callSheetInfo?.attributes?.groups?.filter(item => !!item.contacts.length) || []; // prettier-ignore

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <Header
          artist={getName(jobDetailsData?.artists?.[0]?.artist)}
          jobName={jobDetailsData?.name || ""}
          clientName={getName(jobDetailsData?.client)}
          date={getDateFormat(callSheetInfo?.date || "", "ddd, MMM DD, YYYY")}
          callSheetName={callSheetInfo?.name || ""}
        />
        <Locations
          image={callSheetInfo?.image || ""}
          locations={callSheetInfo?.attributes?.locations || []}
        />
        <Schedule scheduleList={callSheetInfo?.attributes?.schedule || []} />
        <Contacts contactsGroup={contactsList} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: pxToPt(35)
  }
});
