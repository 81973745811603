import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CreateNewCallSheet} from "../CreateNewCallSheet/CreateNewCallSheet";
import {ButtonGroup} from "components/ButtonGroup/ButtonGroup";
import {Link} from "react-router-dom";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Icon} from "components/Icon/Icon";
import {CallSheetDelete} from "../modals/CallSheetDelete";
import {onSortName} from "../helpers";
import {CallSheetRename} from "../modals/CallSheetRename";
import {useCallSheetHeader} from "./useCallSheetHeader";
import {CallSheetDuplicate} from "../modals/CallSheetDuplicate";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {tCallSheetList} from "api/callSheet/types";
import {useContextJobCallSheet} from "pages/Jobs/JobDetails/JobCallSheets/context/JobCallSheetContext";

import "./CallSheetHeader.scss";
import {tReturnDataFromUseCallSheetPdf} from "../CallSheetPdfTemplate/useCallSheetPdf";

type tCallSheetHeaderProps = {
  pdf: tReturnDataFromUseCallSheetPdf;
};

export const CallSheetHeader: FC<tCallSheetHeaderProps> = ({pdf}) => {
  const {callSheetId} = useContextJobCallSheet();

  const {
    burgerAnchor,
    anchor,
    showModal,
    onShowModal,
    onChangeActiveSheet,
    showSelectSheetMenu,
    isTrack,
    closeModal,
    callSheetList,
    callSheetInfo,
    setShowModal,
    setAnchor,
    setBurgerAnchor,
    isLoading,
    deliveryInfo
  } = useCallSheetHeader();

  const sortedList = onSortName(callSheetList);

  const isDisabledLink = !!deliveryInfo?.groups ? "" : "disabled";
  const isTrackActive = isTrack ? "active" : "";
  const isBuildingActive = isTrack ? "" : "active";

  return (
    <>
      <div className="CallSheetHeader">
        <div>
          <h2 className="CallSheetHeader-title">CALL SHEETS</h2>
        </div>
        <div className="CallSheetHeader-rightSide">
          <ButtonGroup>
            <Link className={isBuildingActive} to={callSheetId || ""}>
              Build
            </Link>

            {isDisabledLink && (
              <Tooltip message="Available after call sheet is sent" position="bottom">
                <Link className={`${isTrackActive} ${isDisabledLink}`} to={callSheetId || ""}>
                  Track
                </Link>
              </Tooltip>
            )}

            {!isDisabledLink && (
              <Link className={`${isTrackActive} ${isDisabledLink}`} to={`${callSheetId}/track`}>
                Track
              </Link>
            )}
          </ButtonGroup>
          <Button
            className="CallSheetHeader-sheetsMenu"
            size="sm"
            color="outline"
            onClick={showSelectSheetMenu}
            loading={isLoading}
          >
            <span>{callSheetInfo?.name}</span>
            <Icon icon="triangle-down" size="xsm" />
          </Button>
          <DropdownMenu
            className="CallSheetHeader-dropdown"
            anchor={anchor}
            onClose={() => setAnchor(null)}
          >
            {sortedList?.map((item: tCallSheetList) => (
              <Button
                key={item.id}
                className={item.name === callSheetInfo?.name ? "active" : ""}
                onClick={() => onChangeActiveSheet(String(item.id))}
                size="sm"
              >
                {item.name}
              </Button>
            ))}
            <hr />
            <Button size="sm" onClick={() => onShowModal("new")}>
              Create new call sheet
            </Button>
          </DropdownMenu>

          <Button onClick={showSelectSheetMenu} size="sm" color="outline" name="burger">
            <Icon icon="menu" size="xsm" />
          </Button>
          <DropdownMenu
            className="CallSheetHeader-dropdown"
            anchor={burgerAnchor}
            onClose={() => setBurgerAnchor(null)}
          >
            <Button onClick={() => onShowModal("rename")} size="sm">
              Rename
            </Button>
            <Button onClick={() => onShowModal("duplicate")} size="sm">
              Duplicate
            </Button>
            <div className="CallSheetHeader-submenuWrap">
              <Button size="sm">
                Export
                <Icon icon="triangle-right" size="xsm" />
              </Button>
              <div className="submenu">
                <Button size="sm" loading={pdf?.instance?.loading} onClick={pdf?.onDownloadPdf}>
                  {pdf?.instance?.loading ? "Exporting PDF..." : " Export as pdf"}
                </Button>
                <Button
                  size="sm"
                  loading={pdf?.isSavingToDropbox}
                  onClick={pdf?.onSavePdfToDropbox}
                >
                  Save to dropbox
                </Button>
              </div>
            </div>

            <Button onClick={() => onShowModal("delete")} size="sm">
              Delete
            </Button>
          </DropdownMenu>
        </div>

        {showModal === "new" && (
          <ModalPopup show={true} onClose={() => setShowModal(null)} withoutPaddings size="w-528">
            <CreateNewCallSheet closeModal={closeModal} />
          </ModalPopup>
        )}
        {showModal === "delete" && <CallSheetDelete onClose={closeModal} />}
        {showModal === "rename" && <CallSheetRename onClose={closeModal} />}
        {showModal === "duplicate" && <CallSheetDuplicate onClose={closeModal} />}
      </div>
    </>
  );
};
