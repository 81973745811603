import {FC} from "react";
import {StyleSheet, View} from "@react-pdf/renderer";
import {tAdvanceDetails} from "api/budget/advance/types";
import moment from "moment";
import {InfoBlockContainer} from "./_InfoBlockContainer";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {tInvoice} from "api/invoice/types";

type tInvoiceDetailsProps = {data: tAdvanceDetails | tInvoice};

export const InvoiceDetails: FC<tInvoiceDetailsProps> = ({data}) => {
  const invoiceDetailsData = [
    {
      label: "Invoice no.",
      value: data?.invoice_number
    },
    {
      label: "Date",
      value: data?.date ? moment(data.date).format("MMMM DD, YYYY") : null
    },
    {
      label: "Terms",
      value: data?.terms
    },
    {
      label: "Due Date",
      value: data?.due_date ? moment(data.due_date).format("MMMM DD, YYYY") : null
    },
    {
      label: "CONTACT",
      value: data?.advance_contact?.name || ""
    },
    {
      label: "",
      value: data?.advance_contact?.phone || ""
    },
    {
      label: "",
      value: data?.advance_contact?.email || ""
    }
  ];

  return (
    <InfoBlockContainer title="Details">
      {invoiceDetailsData.map((item, i) => {
        if (!item?.value) return null;
        return (
          <View key={i} style={styles.row}>
            <View style={styles.labelContainer}>
              <PDFText fontFamily="Helvetica700" style={styles.label}>
                {item.label}
              </PDFText>
            </View>
            <View style={styles.valueContainer}>
              <PDFText style={styles.value}>{item.value}</PDFText>
            </View>
          </View>
        );
      })}
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: pxToPt(10)
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: pxToPt(4)
  },
  labelContainer: {
    width: "27%",
    paddingRight: pxToPt(6)
  },
  valueContainer: {
    flex: 1
  },
  label: {
    textTransform: "uppercase",
    fontSize: pxToPt(13)
  },
  value: {
    fontSize: pxToPt(13)
  }
});
