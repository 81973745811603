import {FC} from "react";
import {View, StyleSheet} from "@react-pdf/renderer";
import {tTrack} from "api/budget/track/types";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {HeaderRow, CostRow, TotalRow} from "./Track.Parts";

export const Track: FC<{data: tTrack}> = ({data}) => {
  const categories = data.categories;
  const estimates = new Set();

  categories.forEach(cat => {
    cat.cost_values.forEach(value => estimates.add(value?.estimate?.name));
  });

  const isMultipleEstimate = estimates.size > 1;

  return (
    <View>
      {categories.map(({category, cost_values, category_total}, i) => {
        // const isTheVeryLast = i === categories.length - 1;

        return (
          <View key={category?.id} style={{marginBottom: pxToPt(16)}}>
            {cost_values.map(
              ({cost_value, cost_value_expense_total: {total_actual}, estimate}, index) => {
                const isFirst = index === 0;
                const isOnlyOne = cost_values.length === 1;
                const isLast = index === cost_values.length - 1;

                const estimateName = estimate?.name || "";
                const isShowEstimate =
                  (cost_values?.[index - 1] &&
                    isMultipleEstimate &&
                    cost_values[index - 1].estimate?.name) !== estimateName;

                if (isOnlyOne) {
                  return (
                    <View key={cost_value.id} wrap={false}>
                      <HeaderRow category={category} />
                      {isShowEstimate && <PDFText style={styles.estimate}>{estimateName}</PDFText>}
                      <CostRow costValue={cost_value} totalActual={total_actual} />
                      <TotalRow label="Total" value={category_total.total_actual} />
                      {/* {isTheVeryLast && (
                        <TotalRow
                          label="Total actual costs"
                          value={data.grand_total.total_actual}
                        />
                      )} */}
                    </View>
                  );
                }

                if (isFirst) {
                  return (
                    <View key={cost_value.id} wrap={false}>
                      <HeaderRow category={category} />
                      {isShowEstimate && <PDFText style={styles.estimate}>{estimateName}</PDFText>}
                      <CostRow costValue={cost_value} totalActual={total_actual} />
                    </View>
                  );
                }

                if (isLast) {
                  return (
                    <View key={cost_value.id} wrap={false}>
                      {isShowEstimate && <PDFText style={styles.estimate}>{estimateName}</PDFText>}
                      <CostRow costValue={cost_value} totalActual={total_actual} />
                      <TotalRow label="Total" value={category_total.total_actual} />
                      {/* {isTheVeryLast && (
                        <TotalRow
                          label="Total actual costs"
                          value={data.grand_total.total_actual}
                        />
                      )} */}
                    </View>
                  );
                }

                return (
                  <View key={cost_value.id} wrap={false}>
                    {isShowEstimate && <PDFText style={styles.estimate}>{estimateName}</PDFText>}
                    <CostRow costValue={cost_value} totalActual={total_actual} />
                  </View>
                );
              }
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  estimate: {
    fontSize: pxToPt(12),
    textTransform: "uppercase",
    color: "#7E7E80",
    marginBottom: pxToPt(8)
  }
});
