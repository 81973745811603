import {PDFText} from "components/PDFText";
import ReactPDF, {StyleSheet} from "@react-pdf/renderer";
import {pxToPt} from "helpers/transform";
import {InfoBlockContainer} from "./_InfoBlockContainer";

type tTextBlockProps = {
  title: string;
  text: string;
  wrap?: boolean;
  type?: "dark";
  style?: ReactPDF.ViewProps["style"];
};

export const TextBlock = ({title, text, wrap = true, type, style}: tTextBlockProps) => {
  if (!text) return null;
  const textStyle = type === "dark" ? styles.fontSizeDark : styles.fontSize13;
  return (
    <InfoBlockContainer title={title} wrap={wrap} style={style}>
      <PDFText style={textStyle}>{text}</PDFText>
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  fontSize13: {
    fontSize: pxToPt(14),
    color: "#000"
  },
  fontSizeDark: {
    color: "#000",
    lineHeight: pxToPt(2),
    fontSize: pxToPt(14)
  }
});
