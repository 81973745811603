import {FC} from "react";
import {Page, Document, StyleSheet} from "@react-pdf/renderer";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {tAdvanceDetails} from "api/budget/advance/types";
import {pxToPt} from "helpers/transform";
import {Header} from "../../PdfTemplates/letterUS/Header";
import {TwoColumnWrap} from "../../PdfTemplates/letterUS/TwoColumnWrap";
import {Client} from "../../PdfTemplates/letterUS/infoBlocks/Client";
import {PayTo} from "../../PdfTemplates/letterUS/infoBlocks/PayTo";
import {JobDescription} from "../../PdfTemplates/letterUS/infoBlocks/JobDescription";
import {TextBlock} from "../../PdfTemplates/letterUS/infoBlocks/TextBlock";
import {InvoiceDetails} from "../../PdfTemplates/letterUS/infoBlocks/InvoiceDetails";
import {AdvanceDetails} from "../../PdfTemplates/letterUS/tables/AdvanceDetails";

const styles = StyleSheet.create({
  container: {
    padding: pxToPt(35)
  }
});

interface tAdvanceInvoicePreviewDocumentProps {
  jobDetailsData: iJobDetailsDataReturn;
  advanceDetails: tAdvanceDetails;
}

export const AdvanceInvoicePreviewDocument: FC<tAdvanceInvoicePreviewDocumentProps> = ({
  advanceDetails,
  jobDetailsData
}) => {
  const GAP = 20;
  return (
    <Document>
      <Page style={styles.container} size="LETTER">
        <Header text="Advance Invoice" typeLogo="new" />

        <TwoColumnWrap
          style={{marginBottom: pxToPt(GAP)}}
          leftChildren={<Client client={jobDetailsData.client} />}
          rightChildren={<PayTo />}
        />

        <TwoColumnWrap
          style={{marginBottom: pxToPt(GAP)}}
          leftChildren={<JobDescription data={advanceDetails} jobData={jobDetailsData} />}
          rightChildren={<InvoiceDetails data={advanceDetails} />}
        />

        {/* <TwoColumnWrap
          style={{marginBottom: pxToPt(GAP)}}
          leftChildren={<TextBlock title="Usage license" text={advanceDetails?.usage_license} />}
          rightChildren={<TextBlock title="Creative" text={advanceDetails?.creative} />}
          /> */}

        <AdvanceDetails data={advanceDetails} />

        <TextBlock
          title="Notes"
          text={advanceDetails?.notes}
          style={{marginBottom: pxToPt(24), marginTop: pxToPt(10)}}
        />

        <TwoColumnWrap
          leftChildren={
            <TextBlock title="Bank" type="dark" text={advanceDetails?.bank_detail?.details || ""} />
          }
        />
      </Page>
    </Document>
  );
};
