import {View, StyleSheet} from "@react-pdf/renderer";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat} from "helpers/formattingData";
import {PDFText} from "components/PDFText";
import {pxToPt} from "helpers/transform";
import {InfoBlockContainer} from "./_InfoBlockContainer";

type tTotalsProps = {
  estimate: tEstimateData;
};

export const Totals = ({estimate}: tTotalsProps) => {
  const values: {label: string; value: number; className?: string}[] = [
    {label: "Estimated Production Costs", value: estimate.budgeted_costs},
    {label: "Production % on Budgeted Expenses", value: estimate.production_total},
    // {label: "Insurance % on Budgeted Expenses", value: estimate.insurance},
    {label: "Green", value: estimate.green, className: "textGreen"},
    {label: `Sales Tax (${estimate.sales_tax_percent}%)`, value: estimate.sales_tax},
    {label: "TOTAL ESTIMATE", value: estimate.grand_total, className: "textBig"},
    {
      label: `Total Advance Required (${estimate.advance_required_percent}%)`,
      value: estimate.advance_required
    }
  ];

  return (
    <InfoBlockContainer title="Totals">
      {values.map(({label, value, className}) => {
        const isCustomStyle = className && styles[className as keyof typeof styles];
        const textStyle = isCustomStyle ? {...styles.text, ...isCustomStyle} : styles.text;
        const isTextBig = className === "textBig";
        const textFontFamily = isTextBig ? "Helvetica700" : "Helvetica500";
        const totalAmountValue = isTextBig ? {fontSize: pxToPt(20)} : {};

        return (
          <View key={label} style={styles.row}>
            <View style={textStyle}>
              <PDFText fontFamily={textFontFamily}>{label}</PDFText>
            </View>
            <View style={textStyle}>
              <PDFText fontFamily={textFontFamily} style={totalAmountValue}>
                {getUsMoneyFormat(value)}
              </PDFText>
            </View>
          </View>
        );
      })}
    </InfoBlockContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  text: {
    fontSize: pxToPt(13),
    marginBottom: pxToPt(4)
  },
  textGreen: {
    color: "#30CE00"
  },
  textBig: {
    fontSize: pxToPt(18),
    margin: `${pxToPt(10)} 0 0`,
    textTransform: "uppercase"
  }
});
