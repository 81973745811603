import {FC} from "react";
import {Page, Document, View} from "@react-pdf/renderer";
import {tTrack} from "api/budget/track/types";
import {tInvoice} from "api/invoice/types";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {Header} from "../../PdfTemplates/letterUS/Header";
import {pxToPt} from "helpers/transform";
import {TwoColumnWrap} from "../../PdfTemplates/letterUS/TwoColumnWrap";
import {PayTo} from "../../PdfTemplates/letterUS/infoBlocks/PayTo";
import {JobDescription} from "../../PdfTemplates/letterUS/infoBlocks/JobDescription";
import {Client} from "../../PdfTemplates/letterUS/infoBlocks/Client";
import {InvoiceDetails} from "../../PdfTemplates/letterUS/infoBlocks/InvoiceDetails";
import {TextBlock} from "../../PdfTemplates/letterUS/infoBlocks/TextBlock";
import {ProductionCostsTrack} from "../../PdfTemplates/letterUS/tables/ProductionCostsTrack";
import {InvoiceTotals} from "../../PdfTemplates/letterUS/tables/InvoiceTotals";
import {Track} from "../../PdfTemplates/letterUS/tables/Track";

interface iInvoicePreviewProps {
  track: tTrack;
  invoice: tInvoice;
  jobDetails: iJobDetailsDataReturn;
}

export const InvoicePreview: FC<iInvoicePreviewProps> = ({track, jobDetails, invoice}) => {
  return (
    <Document>
      <Page size="LETTER" style={{padding: pxToPt(35)}}>
        <Header text="Invoice" />

        <TwoColumnWrap
          leftChildren={<Client client={jobDetails.client} />}
          rightChildren={<PayTo />}
        />

        <TwoColumnWrap
          leftChildren={<JobDescription data={invoice} jobData={jobDetails} />}
          rightChildren={<InvoiceDetails data={invoice} />}
        />

        <TwoColumnWrap
          leftChildren={<TextBlock title="Notes" text={invoice?.notes} />}
          rightChildren={
            <TextBlock title="Bank" type="dark" text={invoice?.bank_detail?.details || ""} />
          }
        />

        <TwoColumnWrap
          leftChildren={<TextBlock title="Usage license" text={invoice?.usage_license} />}
          rightChildren={<TextBlock title="Creative" text={invoice?.creative} />}
        />

        <ProductionCostsTrack title="Production costs" summaryTitle="Total costs" data={track} />
        <InvoiceTotals invoice={invoice} />

        <View break>
          <Header text="Invoice" />
          <Track data={track} />
        </View>
      </Page>
    </Document>
  );
};
