import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";
import {tReturnDataFromUseCallSheetPdf} from "./useCallSheetPdf";
import "./CallSheetPdfPreview.scss";

interface iCallSheetPdfPreviewProps {
  pdf: tReturnDataFromUseCallSheetPdf;
}

export const CallSheetPdfPreview: FC<iCallSheetPdfPreviewProps> = ({pdf}) => {
  return (
    <div className={`CallSheetPdfTemplate`}>
      <div className="CallSheetPdfTemplate-buttons">
        <Button
          size="md"
          color="lightHoverLight"
          onClick={pdf?.onDownloadPdf}
          disabled={pdf?.instance?.loading}
        >
          {pdf?.instance?.loading ? "Loading PDF..." : "Download PDF"}
        </Button>
        <Button
          size="md"
          disabled={pdf?.isSavingToDropbox}
          loading={pdf?.isSavingToDropbox}
          onClick={pdf?.onSavePdfToDropbox}
          color="lightHoverLight"
        >
          Save to dropbox
        </Button>
      </div>

      {!pdf?.instance?.loading && <PdfViewer blobUrl={pdf?.instance?.url || ""} />}
    </div>
  );
};
