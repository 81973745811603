import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {CallSheetSender} from "../modals/CallSheetSender/CallSheetSender";
import {CallSheetSenderForm} from "../CallSheetSenderForm/CallSheetSenderForm";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {CallSheetLog} from "../modals/CallSheetLog/CallSheetLog";
import {useCallSheetStatusBar} from "./useCallSheetStatusBar";
import {StatusBarText} from "./StatusBarText";
import {tReturnDataFromUseCallSheetPdf} from "../CallSheetPdfTemplate/useCallSheetPdf";
import {CallSheetPdfPreview} from "../CallSheetPdfTemplate/CallSheetPdfPreview";
import "./CallSheetStatusBar.scss";

interface iCallSheetStatusBarProps {
  pdf: tReturnDataFromUseCallSheetPdf;
}

export const CallSheetStatusBar: FC<iCallSheetStatusBarProps> = ({pdf}) => {
  const {
    onSaveCallSheet,
    onRemoveSchedule,
    saveIsLoading,
    showModal,
    removeScheduleLoading,
    updateScheduleLoading,
    deliveryInfo,
    setShowModal,
    onUpdateDelivery
  } = useCallSheetStatusBar();

  const modalSize: any = {
    edit: "md",
    preview: "xlg"
  };

  const handleOnSave = () => {
    setTimeout(() => {
      onSaveCallSheet();
    }, 0);
  };

  const schedule = deliveryInfo?.schedule;
  const isSentCallSheet = !!deliveryInfo?.sent_time;

  const isPending = deliveryInfo?.status?.name === "pending";
  const isScheduled = deliveryInfo?.status?.name === "scheduled";
  const isSent = deliveryInfo?.status?.name === "sent";

  return (
    <>
      <div className="CallSheetStatusBar">
        <div className="CallSheetStatusBar-leftSide">
          {isPending && <span>Sending...</span>}
          {deliveryInfo && (
            <StatusBarText
              hasPrevConfirmations={!!deliveryInfo?.prev_confirmations?.length}
              hasDelivery={isScheduled || isSent}
              schedule={schedule}
              sentTime={deliveryInfo?.sent_time}
            />
          )}
          {isScheduled && (
            <>
              <Button onClick={() => setShowModal("edit")}>Edit</Button>
              <Button onClick={() => setShowModal("unschedule")}>UNSCHEDULE</Button>
            </>
          )}
          {isSent && <Button onClick={() => setShowModal("log")}>View delivery log</Button>}
        </div>
        <div className="CallSheetStatusBar-rightSide">
          {!isScheduled && (
            <Button
              onClick={handleOnSave}
              loading={saveIsLoading}
              className="CallSheetStatusBar-save"
              size="sm"
              color="outline"
            >
              Save changes
            </Button>
          )}

          <Button onClick={() => setShowModal("preview")} size="sm" color="outline">
            Preview
          </Button>

          {!isScheduled && (
            <Button size="sm" color="outline" onClick={() => setShowModal("sender")}>
              {isSentCallSheet ? "SEND UPDATED CALL SHEET" : "Send call sheet"}
            </Button>
          )}
        </div>
      </div>

      <ModalPopup
        show={Object.keys(modalSize).some(k => k === showModal)}
        onClose={() => setShowModal(null)}
        size={showModal && modalSize[showModal]}
        className={showModal === "preview" ? "CallSheetPdfTemplate-modal" : ""}
        withoutPaddings
      >
        {showModal === "edit" && (
          <CallSheetSenderForm
            deliveryInfo={deliveryInfo}
            isLoading={updateScheduleLoading}
            type="schedule"
            submitTitle="Save"
            formWrapProps={{
              headerTitle: "Edit delivery"
            }}
            onSubmitCallback={onUpdateDelivery}
          />
        )}
        {showModal === "preview" && <CallSheetPdfPreview pdf={pdf} />}
      </ModalPopup>

      {showModal === "log" && (
        <ModalPopup
          className="CallSheetLogModal"
          show={true}
          size="w-528"
          onClose={() => setShowModal(null)}
          withoutPaddings
        >
          <CallSheetLog />
        </ModalPopup>
      )}
      {showModal === "sender" && <CallSheetSender onClose={() => setShowModal(null)} />}

      {showModal === "unschedule" && (
        <ConfirmWindow
          show={true}
          onClose={() => setShowModal(null)}
          title="Unschedule delivery?"
          subTitle="This call sheet will be unscheduled."
          cancelBtn={{title: "Cancel"}}
          onConfirm={onRemoveSchedule}
          isLoading={removeScheduleLoading}
          confirmBtn={{
            text: "unschedule"
          }}
        />
      )}
    </>
  );
};
