import {useEffect, useState} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {PDFTemplate} from "./PDFTemplate";
import {getPdfName, getSheetsWithVisibleGroups} from "./helpers";
import {usePDF} from "@react-pdf/renderer";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {callSheet} from "api/callSheet";

export type tReturnDataFromUseCallSheetPdf = ReturnType<typeof useCallSheetPdf>;

export const useCallSheetPdf = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {callSheetInfo, proofedCallSheetInfo} = useContextJobCallSheet();

  const [pdfDocument, setDocument] = useState(
    <PDFTemplate
      callSheetInfo={getSheetsWithVisibleGroups(callSheetInfo)}
      jobDetailsData={jobDetailsData}
    />
  );

  const [instance, updateInstance] = usePDF({document: pdfDocument});

  useEffect(() => {
    const updatedDocument = (
      <PDFTemplate
        callSheetInfo={getSheetsWithVisibleGroups(callSheetInfo)}
        jobDetailsData={jobDetailsData}
      />
    );
    setDocument(updatedDocument);
  }, [callSheetInfo, proofedCallSheetInfo, jobDetailsData]);

  useEffect(() => {
    updateInstance();
  }, [pdfDocument, updateInstance]);

  const onDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = instance.url || "";

    const pdfName = getPdfName({jobDetailsData, proofedCallSheetInfo});

    link.download = pdfName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const saveToDropboxQuery = useHttp();
  const [isSavingToDropbox, setIsSavingToDropbox] = useState(false);
  const [showDropboxAuthModal, setDropboxAuthModal] = useState(false);

  async function onSavePdfToDropbox() {
    if (!proofedCallSheetInfo?.id) return;
    setIsSavingToDropbox(true);
    try {
      const fileBlob = instance?.blob;
      const formData: any = new FormData();
      formData.append("file", fileBlob);
      const response = await saveToDropboxQuery.call(callSheet.saveToDropbox(proofedCallSheetInfo.id, formData)); // prettier-ignore
      eventBus.dispatch("showToast", {message: response.data.message});
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setDropboxAuthModal(true);
        return;
      }
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {message: msg || error?.message, type: "error"});
    } finally {
      setIsSavingToDropbox(false);
    }
  }

  return {
    isSavingToDropbox,
    showDropboxAuthModal,
    onSavePdfToDropbox,
    setDropboxAuthModal,
    onDownloadPdf,
    instance
  };
};
